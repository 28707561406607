<template>
	<data-loader :watch="watchParams" :callback="()=>load()">
		<template #default="{loading}">
			<v-container v-if="data && !loading" class="text-center">
				<v-row dense>
					<v-col>Месец:</v-col>
					<v-col v-for="session in data.sessions" :key="session.id">
						<year-month abbr
							:month="parseInt(session.month)"
							:year="parseInt(session.year)"
						/>
						<span v-if="session.hot_water_na_brl" class="font-weight-bold red--text ml-1">({{ session.broi_lica }})</span>
					</v-col>
				</v-row>
				<v-form ref="form">
					<v-row dense>
						<v-col></v-col>
						<v-col v-for="session in data.sessions" :key="session.id">
							<v-text-field
								:disabled="locked"
								:readonly="locked"
								outlined hide-details="auto" dense
								v-model="values[session.id]"
								:rules="validators"
							/>
						</v-col>
					</v-row>
				</v-form>
				<v-row dense>
					<v-col>Дни БГВ (общо {{ sessionSumInt('work_days_hw') }}):</v-col>
					<v-col v-for="session in data.sessions" :key="session.id">
						{{session.work_days_hw}}
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>Дни отопл. (общо {{ sessionSumInt('work_days_heat') }}):</v-col>
					<v-col v-for="session in data.sessions" :key="session.id">
						{{session.work_days_heat}}
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>Имот:</v-col>
					<v-col v-for="session in sessionsWithImotClientChanges" :key="session.id" :class="{'font-weight-bold orange--text': session.imot_N_change}">
						{{session.imot_N}}
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>Клиент:</v-col>
					<v-col v-for="session in sessionsWithImotClientChanges" :key="session.id" :class="{'font-weight-bold orange--text': session.client_N_change}">
						{{session.client_N}}
					</v-col>
				</v-row>
				<v-row dense v-if="!locked" class="mt-2">
					<v-col :cols="data.sessions.length+1" class="text-right">
						<v-btn color="primary" small @click="save">Запис</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</data-loader>
</template>

<script>
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import DataLoader from "@/ittijs/components/DataLoader";
import YearMonth from "@/ittijs/components/YearMonth";

class Model extends ITTIModelBase {
	constructor() {
		super('ImotDataIzr');
	}
	getData(session, imot, station) {
		return this.fetch('getData', {session, imot, station});
	}
	saveData(session, imot, station, values) {
		return this.fetch('saveData', {session, imot, station}, values);
	}
}

const model = new Model;

export default {
	components: {YearMonth, DataLoader},
	props: [
		'session',
		'imot',
		'station',
		'locked',
	],
	data(){
		return {
			model,
			data: null,
			values: {},
			validators: [
				val => {
					if (typeof val === "undefined" || val === '' || val.toString().match(/^\d*$/)) {
						return true;
					}
					return "Брой лица";
				},
			],
		}
	},
	computed: {
		watchParams(){
			return {
				session: this.session,
				imot: this.imot,
				station: this.station,
			}
		},
		sessionsWithImotClientChanges(){
			let lastImot, lastClient;
			return this.data?.sessions.map(session => {
				const imot_N_change   = typeof lastImot   !== 'undefined' ? lastImot   !== session.imot_N   : false;
				const client_N_change = typeof lastClient !== 'undefined' ? lastClient !== session.client_N : false;
				lastImot = session.imot_N;
				lastClient = session.client_N;
				return {
					...session,
					imot_N_change,
					client_N_change,
				}
			}) || null;
		},
	},
	methods: {
		async load(){
			this.data = null;
			this.values = {};
			this.data = await model.getData(this.session, this.imot, this.station);
			if (this.data) {
				for (const s of this.data.sessions) {
					if (this.data.data[ s.id ] === null) {
						this.values[s.id] = '';
					}
					else {
						this.values[s.id] = this.data.data[s.id].toString();
					}
				}
			}
		},
		async save(){
			if (this.$refs.form.validate()) {
				await model.saveData(this.session, this.imot, this.station, this.values);
				await this.load();
				this.$emit('reload');
			}
		},
		sessionSumInt(field){
			return this.data && this.data.sessions.reduce(
				(prev,curr) => prev + (curr[field] || 0),
				0
			) || 0;
		},
	},
}
</script>