<template>
	<div style="display: contents;">
		<list
			ref="list"
			v-if="imodel.loaded"
			:imodel="imodel"
			headers="id,created_time,deadline,current_status"
			:params="params"
			hide-select
			hide-export
			:hide-delete="!authorization.hasRole(RolesEnum.TICKET_ADMIN)"
			@edit="onEdit($event.id)"
			disable-pagination
		>
			<template #top-actions>
				<v-btn v-if="!noNewRecord" small color="primary" class="my-1" @click="onAdd">
					<v-icon left>mdi-plus</v-icon>
					Нов запис
				</v-btn>
			</template>
			<template #item.id="{item}">
				<ref-num-tag :ref-num="item.id" />
			</template>
			<template #item.deadline="{item}">
				<ticket-deadline :date="item.__raw.deadline" :status="item.__raw.current_status" />
			</template>
			<template #item.current_status="{item}">
				<v-chip label small :color="TicketStatusMap.get(item.__raw.current_status)?.color" text-color="white" class="d-flex">
					<v-icon left small>{{ TicketStatusMap.get(item.__raw.current_status)?.icon }}</v-icon>
					{{ TicketStatusMap.get(item.__raw.current_status)?.label }}
				</v-chip>
			</template>
		</list>
		<edit-popup ref="editPopup"
			:edit-component="EditProxy"
			title=" "
			:imodel="imodel"
			:id="editId"
			:constrains="editConstrains"
			:props-dialog="{top: true}"
			:dialog-max-width="1400"
			:fields="editFields"
			:disabled-fields="disabledEditFields"
			:edit-button-options="{
				showDelete: authorization.hasRole(RolesEnum.TICKET_ADMIN),
			}"
			@save="editId = $event.id"
			@close="onReload"
			@reload="onReload"
		>
			<template #edit.before-fields="{row}">
				<data-loader
					v-if="selectedUtility && row['imot_id']"
					:watch="{utility: selectedUtility, imot: row['imot_id']}"
					:callback="() => imodel.getImotInfo(selectedUtility, row['imot_id'])"
					:data.sync="imotInfo"
				>
					<template #default>
						<v-row v-if="imotInfo" no-gutters justify="center">
							<v-alert type="info" text dense>
								Имот {{imotInfo.imot_N}}, {{imotInfo.address}}
							</v-alert>
						</v-row>
					</template>
				</data-loader>
			</template>

		</edit-popup>
	</div>
</template>
<script>
import List from "@/ittijs/List.vue";
import Model from "@/views/tickets/Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import EditPopup from "@/ittijs/components/EditPopup.vue";
import {RolesEnum} from "@/ittijs/Authorization";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import EditProxy from "@/views/tickets/EditProxy.vue";
import {TicketStatusMap} from "@/ittijs/Nomenclatures";
import RefNumTag from "@/views/tickets/RefNumTag.vue";
import TicketDeadline from "@/views/tickets/TicketDeadline.vue";

const imodel = ITTIModelProvider.getModel(Model);

export default {
	components: {TicketDeadline, RefNumTag, DataLoader, EditPopup, List},
	mixins    : [
		SelectedUtilityMixin,
	],
	inject    : ['authorization'],
	props     : {
		station: {},
		imot: {},
		noNewRecord: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			imodel,
			editId          : null,
			servicesData    : {},
			editScheduleId  : null,
			editScheduleTime: null,
			imotInfo        : null,
		}
	},
	computed: {
		editConstrains(){
			const result = {
				utility_code: this.selectedUtility,
			};
			if (this.imot) {
				result['imot_id'] = this.imot;
				result['station_id'] = null;
			}
			if (this.station) {
				result['station_id'] = this.station;
				result['imot_id'] = null;
			}
			return result;
		},
		TicketStatusMap() {
			return TicketStatusMap
		},
		EditProxy() {
			return EditProxy
		},
		RolesEnum() {
			return RolesEnum
		},
		params() {
			return {
				station_id : this.station,
				imot_id    : this.imot,
			}
		},
		editFields() {
			if (this.editId === 'new') {
				return [
					'status',
					'scheduled_duration_minutes',
					'notes_operator',
				];
			} else {
				return [
					'created_time',
					'created_by',
					'status',
					'scheduled_duration_minutes',
					'payment_type',
					'notes_operator',
					'time_started',
					'time_completed',
					'completed_by',
					'notes_montajnik',
				];
			}
		},
		disabledEditFields() {
			return [
				'created_time',
				'created_by',
				'time_started',
				'time_completed',
				'completed_by',
				'notes_montajnik',
			];
		},
	},
	methods : {
		onAdd() {
			this.editId = 'new';
			this.$refs.editPopup.open();
		},
		onEdit(id) {
			this.editId = id;
			this.$refs.editPopup.open();
		},
		onReload() {
			this.$refs.list.refresh();
		},
	},
}
</script>