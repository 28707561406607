<template>
	<my-expand class="fill-height" :expand="expand" @update:expand="$emit('update:expand', $event)">

		<template #title>
			Имот {{imot.data.imot_N}}<span v-if="imot.imot.imot_N_alt">&nbsp;(алт. {{imot.imot.imot_N_alt}})</span>: {{imot.data.address}}, {{imot.data.client_name}}
		</template>

		<template #title-right>

			<btn-loading-promise
				v-if="mesechen || izravnitelna"
				:click="()=>flipZaVizOtchet()"
				@ready="$emit('reload')"
			>
				<template #default="{click,loading}">
					<v-btn
						@click.stop="click"
						:loading="loading"
						:elevation="locked ? 0 : null"
						:ripple="!locked"
						small class="ml-2" :class="{
							error: zaVizOtchet,
							success: !zaVizOtchet,
						}"
					>
						<v-icon left>{{ zaVizOtchet ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
						За визуален отчет
					</v-btn>
				</template>
			</btn-loading-promise>

			<btn-loading-promise
				v-if="korekcionna"
				:click="()=>flipReklamacia()"
				@ready="$emit('reload')"
			>
				<template #default="{click,loading}">
					<v-btn
						@click.stop="click"
						:loading="loading"
						:elevation="locked ? 0 : null"
						:ripple="!locked"
						small class="ml-2" :class="{
							error: reklamacia,
							success: !reklamacia,
						}"
					>
						<v-icon left>{{ reklamacia ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
						Рекламация
					</v-btn>
				</template>
			</btn-loading-promise>

			<btn-loading-promise
				v-if="mesechen || izravnitelna"
				:click="val => setNeotcheten(val)"
				@ready="$emit('reload')"
			>
				<template #default="{click,loading}">
					<v-menu bottom offset-y :disabled="loading">
						<template #activator="{attrs,on}">
							<v-btn
								:loading="loading"
								v-bind="attrs" v-on="on"
								@click.stop
								:elevation="locked ? 0 : null"
								:ripple="!locked"
								small class="ml-2" :class="{
							warning: neotcheten===2,
							error:   neotcheten===1,
							success: neotcheten===0,
						}"
							>
								<v-icon left v-if="neotcheten===0">mdi-checkbox-blank-outline</v-icon>
								<v-icon left v-else-if="neotcheten===1">mdi-checkbox-outline</v-icon>
								<v-icon left v-else-if="neotcheten===2">mdi-checkbox-outline</v-icon>

								<span v-if="neotcheten===0">Неотчетен</span>
								<span v-else-if="neotcheten===1">Неотчетен</span>
								<span v-else-if="neotcheten===2">Необитаем</span>
							</v-btn>
						</template>
						<v-list dense>
							<v-list-item link @click="click(0)">
								<v-list-item-icon><v-icon>mdi-selection-remove</v-icon></v-list-item-icon>
								<v-list-item-content>Изчисти</v-list-item-content>
							</v-list-item>
							<v-list-item link @click="click(1)">
								<v-list-item-icon><v-icon>mdi-file-question</v-icon></v-list-item-icon>
								<v-list-item-content>Неотчетен</v-list-item-content>
							</v-list-item>
							<v-list-item link @click="click(2)">
								<v-list-item-icon><v-icon>mdi-home-flood</v-icon></v-list-item-icon>
								<v-list-item-content>Необитаем</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
			</btn-loading-promise>

			<v-dialog v-if="!locked" v-model="editImot" width="700">
				<template v-slot:activator="{ on, attrs }">
					<v-btn right icon small v-bind="attrs" v-on="on" class="ml-4">
						<v-icon dense color="primary">mdi-pencil</v-icon>
					</v-btn>
				</template>
				<template v-slot:default="">
					<v-card>
						<edit-column
							max-content-height="calc(90vh - 120px)"
							v-if="editImot"
							title="Редакция имот"
							:id="imot.data.id"
							@close="editImot = false"
							@saveclose="editImot = false; $emit('reload')"
							ref="EditForm"
							:imodel="model"
							:edit-button-options="{showDelete:false}"
							hidden-fields="utility_code,session_id,station_id,imot_id,prognozen_dial_heat,prognozen_dial_bgv,station_zone,e_hot_water,e_sgri,e_heat,neotcheten,reklamacia,izr_json,izr_brl_json,sum_work_days_hw,sum_work_days_heat,izr_amount_invoiced,izr_amount_razpr,utility_import_json"
							:left-col-width="200"
						/>
					</v-card>
				</template>
			</v-dialog>
		</template>

		<ep-list accordion multiple flat>

			<ep-panel>
				<ep-header class="px-4 py-3" style="min-height:32px">Имот</ep-header>
				<ep-content>
					<v-simple-table dense>
						<tbody>
							<tr>
								<td>Тип</td>
								<td><enum-value code="imot_type" :value="imot.data.imot_type" /></td>
							</tr>
							<tr v-if="imot.imot.imot_N_alt">
								<td>Номер (алт.):</td>
								<td>{{ imot.imot.imot_N_alt }}</td>
							</tr>
							<tr v-if="imot.imot.radiator_broi_proekt">
								<td>Брой отопл. тела по проект:</td>
								<td>{{ imot.imot.radiator_broi_proekt }}</td>
							</tr>

							<tr v-for="f in [
								'location',
								'volume',
								'hot_water_na_brl',
								'broi_lica',
								'client_N',
								'client_type',
								'client_name',
								'client_phone',
								'client_phone2',
								'client_email',
								'client_gdpr',
								'client_otkaz_hartien_nositel',
								'prognozen_dial_heat',
								'prognozen_dial_sgri',
								]" :key="f">
								<td nowrap width="1%"><field-label :name="f" />:</td>
								<td><field-value :name="f" :value="imot.data[f]" /></td>
							</tr>

							<tr v-if="imot.imot.portal_email">
								<td nowrap>Кл. портал - Имейл:</td>
								<td>{{ imot.imot.portal_email }}</td>
							</tr>
							<tr v-if="imot.imot.portal_phone">
								<td nowrap>Кл. портал - Телефон:</td>
								<td>{{ imot.imot.portal_phone }}</td>
							</tr>
							<tr v-if="imot.imot.portal_name">
								<td nowrap>Кл. портал - Име:</td>
								<td>{{ imot.imot.portal_name }}</td>
							</tr>
							<tr v-if="imot.imot.portal_reg_date">
								<td nowrap>Кл. портал - Дата на регистрация:</td>
								<td><date-format :date="imot.imot.portal_reg_date" /></td>
							</tr>
							<tr v-if="imot.imot.notes!=''">
								<td colspan="2" class="py-2">Бележки (имот)<br/><div style="white-space: pre-wrap">{{ imot.imot.notes }}</div></td>
							</tr>
						</tbody>
					</v-simple-table>
				</ep-content>
				<v-divider/>
			</ep-panel>

			<ep-panel>
				<ep-header class="px-4 py-3" style="min-height:32px">Бележки</ep-header>
				<ep-content>
					<imot-data-notes
						:imot="imot.imot.id"
						height="150"
					/>
				</ep-content>
				<v-divider/>
			</ep-panel>

			<ep-panel>
				<ep-header class="px-4 py-3" style="min-height:32px">Деловодство</ep-header>
				<ep-content>
					<tickets
						:imot="imot.data.imot_id"
						no-new-record
					/>
				</ep-content>
				<v-divider/>
			</ep-panel>

			<ep-panel>
				<ep-header class="px-4 py-3" style="min-height:32px">Документи</ep-header>
				<ep-content>
					<document-list
						:locked="locked"
						:imot="imot.data.imot_id"
						:documents="documents"
						@update:documents="$emit('update:documents', $event)"
					/>
				</ep-content>
				<v-divider/>
			</ep-panel>

			<ep-panel v-if="imot.UPList && imot.UPList.length">
				<ep-header class="px-4 py-3" style="min-height:32px">Уведомителни писма</ep-header>
				<ep-content>
					<v-divider/>
					<UPList :items="imot.UPList" @reload="$emit('reload')" />
				</ep-content>
				<v-divider/>
			</ep-panel>

			<ep-panel v-if="imot.history && imot.history.length > 1">
				<ep-header class="px-4 py-3" style="min-height:32px">Промяна на собственост</ep-header>
				<ep-content>
					<v-list three-line>
						<template v-for="(item, index) in imot.history">
							<v-divider :key="index" />
							<v-subheader :key="index">
								<year-month
									:year="parseInt(item.year)"
									:month="parseInt(item.month)"
								/>
							</v-subheader>
							<v-list-item :key="index">
								<v-list-item-content>
									<v-list-item-title>{{item.imot_N}}: {{item.client_name}}</v-list-item-title>
									<v-list-item-subtitle>
										<v-chip label v-if="item.client_phone!=''" class="mr-2">
											<v-icon left small>mdi-phone-classic</v-icon>
											{{item.client_phone}}
										</v-chip>
										<v-chip label v-if="item.client_phone2!=''" class="mr-2">
											<v-icon left small>mdi-phone-classic</v-icon>
											{{item.client_phone2}}
										</v-chip>
										<v-chip label v-if="item.client_email!=''" class="mr-2">
											<v-icon left small>mdi-at</v-icon>
											{{item.client_email}}
										</v-chip>
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list>
				</ep-content>
				<v-divider/>
			</ep-panel>

			<ep-panel v-if="showJobRequests">
				<ep-header class="px-4 py-3" style="min-height:32px">Заявки</ep-header>
				<ep-content>
					<job-requests
						:imot="imot.data.imot_id"
						:station="imot.data.station_id"
					/>
				</ep-content>
			</ep-panel>

		</ep-list>
	</my-expand>
</template>

<script>
import EnumValue from '@/ittijs/components/EnumValue.vue'
import Model from '../imot_data/Model';
import DocumentList from './DocumentList.vue';
import ImotDataNotes from './ImotDataNotes.vue';
import EditColumn from "@/ittijs/EditColumn";
import FieldLabel from "@/ittijs/components/FieldLabel";
import FieldValue from "@/ittijs/components/FieldValue";
import MyExpand from "@/ittijs/components/MyExpand";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import YearMonth from "@/ittijs/components/YearMonth";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import ExpansionPanelShort from "@/ittijs/ExpansionPanelShort";
import UPList from "@/ittijs/components/UvedomitelniPisma/UPList.vue";
import JobRequests from "@/views/frontdesk/JobRequests.vue";
import {RolesEnum} from "@/ittijs/Authorization";
import Tickets from "@/views/frontdesk/Tickets.vue";

const model = new Model;

export default {
	components: {
		Tickets,
		JobRequests,
		UPList,
		DateFormat,
		YearMonth,
		BtnLoadingPromise,
		MyExpand,
		FieldValue,
		FieldLabel,
		EditColumn,
		EnumValue,
		DocumentList,
		ImotDataNotes,
	},
	mixins: [ExpansionPanelShort],
	provide: {
		imodel: model,
	},
	inject: [
		'authorization',
	],
	props: [
		'imot',
		'documents',
		'locked',
		'mesechen',
		'izravnitelna',
		'korekcionna',
		'expand',
	],
	computed: {
		zaVizOtchet(){
			return this.imot && this.imot.data && this.imot.data.za_viz_otchet === '1';
		},
		neotcheten(){
			return this.imot && this.imot.data && parseInt(this.imot.data.neotcheten);
		},
		reklamacia(){
			return this.imot && this.imot.data && this.imot.data.reklamacia === '1';
		},
		showJobRequests(){
			return this.authorization.hasRole(RolesEnum.JOB_REQUESTS);
		},
	},
	data(){
		return {
			editImot: false,
			model,
		};
	},
	methods: {
		flipZaVizOtchet(){
			return model.setZaVizOtchet(this.imot.data.session_id, this.imot.data.imot_id, this.imot.data.station_id, !this.zaVizOtchet);
		},
		setNeotcheten(value){
			return model.setNeotcheten(this.imot.data.session_id, this.imot.data.imot_id, this.imot.data.station_id, value);
		},
		flipReklamacia(){
			return model.setReklamacia(this.imot.data.session_id, this.imot.data.imot_id, this.imot.data.station_id, !this.reklamacia);
		},
	},
}
</script>