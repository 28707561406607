<template>
	<my-dialog title="HwM3ToMwh" @open="onOpen" ref="dialog" width="1100">
		<template #activator="scope">
			<slot name="activator" v-bind="scope" />
		</template>

		<div v-if="loading" class="text-center pa-4">
			<v-progress-circular indeterminate color="grey" />
		</div>

		<div v-else>
			<v-container>

				<v-simple-table dense class="text-right">
					<thead>
					<tr>
						<th class="text-right">Месец</th>
						<th class="text-right">Дни без водомер</th>
						<th class="text-right">Общ водомер</th>
						<th class="text-right">Изчислен</th>
						<th class="text-right">Коеф.</th>
						<th class="text-right">Вода за разпр.</th>
						<th class="text-right">Коеф.</th>
						<th class="text-right">Сума имоти</th>
						<th class="text-right">Сума бр.л.</th>
						<th class="text-right">Коеф.</th>
						<th class="text-right">Енергия</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(item, i) in data.summer_list" :key="i">
						<td nowrap><year-month abbr :year="item.year" :month="item.month" /></td>
						<td>{{round(item.work_days_wo_hot_vodomer, 0)}}</td>
						<td>{{round(item.vodomer_hot_value, 3)}}</td>
						<td>{{round(item.vodomer_hot_calculated, 3)}}</td>
						<td>{{item.kef_obsht_vodomer}}</td>
						<td>{{round(item.voda_za_razpr_m3, 3)}}</td>
						<td>{{item.kef_voda_za_razpr_m3}}</td>
						<td>{{round(item.SumHW_Vodomeri, 3)}}</td>
						<td>{{round(item.SumHW_BRL, 3)}}</td>
						<td>{{item.kef_individualni_vodomeri}}</td>
						<td>{{round(item.e_hot_water, 6)}}</td>
					</tr>
					</tbody>
					<tfoot>
					<tr>
						<th class="text-right">ОБЩО</th>
						<td class="font-weight-bold">{{sum_work_days_wo_hot_vodomer}}</td>
						<td class="font-weight-bold">{{sum_vodomer_hot_value}}</td>
						<td class="font-weight-bold">{{sum_vodomer_hot_calculated}}</td>
						<td></td>
						<td class="font-weight-bold">{{sum_voda_za_razpr_m3}}</td>
						<td></td>
						<td class="font-weight-bold">{{sum_SumHW_Vodomeri}}</td>
						<td class="font-weight-bold">{{sum_SumHW_BRL}}</td>
						<td></td>
						<td class="font-weight-bold">{{sum_e_hot_water}}</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td nowrap colspan="3" class="text-center pb-2">
							<v-divider class="primary my-2" style="border-top-width: 2px" />
							A = {{ SumA }}
						</td>
						<td nowrap colspan="2" class="text-center pb-2">
							<v-divider class="primary my-2" style="border-top-width: 2px" />
							B = {{SumB}}
						</td>
						<td nowrap colspan="3" class="text-center pb-2">
							<v-divider class="primary my-2" style="border-top-width: 2px" />
							C = {{SumC}}
						</td>
						<td nowrap class="pb-2">
							<v-divider class="primary my-2" style="border-top-width: 2px" />
							D = {{SumD}}
						</td>
					</tr>
					<tr>
						<th class="text-right">КОЕФ.</th>
						<td></td>
						<td nowrap colspan="3" class="text-center py-2">
							<v-btn outlined block @click="valueInput = DdivA.toString()">
								D / A = {{ DdivA }}
							</v-btn>
						</td>
						<td nowrap colspan="2" class="py-2">
							<v-btn outlined block @click="valueInput = DdivB.toString()">
								D / B = {{ DdivB }}
							</v-btn>
						</td>
						<td nowrap colspan="3" class="text-center py-2">
							<v-btn outlined block @click="valueInput = DdivC.toString()">
								D / C = {{ DdivC }}
							</v-btn>
						</td>
						<td></td>
					</tr>
					</tfoot>
				</v-simple-table>

				<v-divider class="my-2" />

				<v-row dense>
					<v-col class="d-flex justify-center">
						<v-form ref="form">
							<decimal-input
								label="hw_m3_to_mwh_summer" v-model="valueInput"
								:rules="rules"
								outlined dense style="width: 250px;" class="flex-grow-0 mt-4"
							/>
						</v-form>
					</v-col>
				</v-row>

				<v-row dense>
					<v-col class="d-flex justify-center">
						1.2 * 52kWh/m3 = 0.0624 MWh/m3 (За нови сгради чл.86,ал.1,т.3)
					</v-col>
				</v-row>

				<v-row v-if="data && data.winter_list">
					<v-col>
						<v-simple-table dense style="overflow: hidden">
							<thead class="headings2">
							<tr>
								<th :colspan="data.winter_list.length">
									<field-label :imodel="sdmodel" name="hw_m3_to_mwh_summer" />
								</th>
							</tr>
							<tr>
								<th v-for="(item, index) in data.winter_list" :key="index">
									<year-month abbr :year="parseInt(item.year)" :month="parseInt(item.month)" />
								</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td v-for="(item, index) in data.winter_list" :key="index">
									{{ item.hw_m3_to_mwh_summer }}
								</td>
							</tr>
							</tbody>
						</v-simple-table>
					</v-col>
				</v-row>

			</v-container>
		</div>

		<v-divider />

		<div class="d-flex pa-2">
			<v-spacer/>
			<v-btn text @click="close">Затвори</v-btn>
			<btn-loading-promise
				v-if="!readonly"
				:click="()=>update()"
				@ready="$emit('ready'); close();"
				@error="$emit('error', $event)"
			>
				<template #default="{click,loading}">
					<v-btn color="primary" @click="click" :loading="loading" class="ml-2">
						Потвърди
					</v-btn>
				</template>
			</btn-loading-promise>
		</div>
	</my-dialog>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
import IModelMixin from "@/ittijs/IModelMixin";
import YearMonth from "@/ittijs/components/YearMonth";
import DecimalInput from "@/ittijs/Inputs/DecimalInput";
import Validators from "@/ittijs/Validators";
import FieldLabel from "@/ittijs/components/FieldLabel";
import SDModel from "@/views/station_data/Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const sdmodel = ITTIModelProvider.getModel(SDModel);

export default {
	mixins: [IModelMixin],
	components: {FieldLabel, DecimalInput, YearMonth, BtnLoadingPromise, MyDialog},
	props: [
		'session',
		'station',
		'valueInit',
		'readonly',
	],
	data(){
		return {
			sdmodel,
			data: {},
			loading: false,
			valueInput: '',
			rules: [
				Validators.decimal(6, 6),
			],
		}
	},
	computed: {
		diff(){
			const diff = (this.valFromImoti - this.valFromStation) / this.valFromStation;
			return (diff * 100).toFixed(1);
		},
		diffTooBig(){
			return this.diff >= 30 || this.diff <= -30;
		},
		valFromStation(){
			return this.data.vodomer_hot_value + this.data.vodomer_hot_calculated;
		},
		valFromImoti(){
			return this.data.sum_vodomeri + this.data.sum_brl;
		},
		sum_work_days_wo_hot_vodomer() {
			return this.dataSum('work_days_wo_hot_vodomer', 0);
		},
		sum_vodomer_hot_value() {
			return this.dataSum('vodomer_hot_value', 3);
		},
		sum_vodomer_hot_calculated() {
			return this.dataSum('vodomer_hot_calculated', 3);
		},
		sum_voda_za_razpr_m3() {
			return this.dataSum('voda_za_razpr_m3', 3);
		},
		sum_SumHW_Vodomeri() {
			return this.dataSum('SumHW_Vodomeri', 3);
		},
		sum_SumHW_BRL() {
			return this.dataSum('SumHW_BRL', 3);
		},
		sum_e_hot_water() {
			return this.dataSum('e_hot_water', 6);
		},
		SumA(){
			return this.round(
				parseFloat(this.sum_vodomer_hot_value) +
				parseFloat(this.sum_vodomer_hot_calculated)
				, 3);
		},
		SumB(){
			return this.sum_voda_za_razpr_m3;
		},
		SumC(){
			return this.round(
				parseFloat(this.sum_SumHW_Vodomeri) +
				parseFloat(this.sum_SumHW_BRL)
				, 3);
		},
		SumD(){
			return this.sum_e_hot_water;
		},
		DdivA(){
			return this.round(parseFloat(this.SumD) / parseFloat(this.SumA), 6);
		},
		DdivB(){
			return this.round(parseFloat(this.SumD) / parseFloat(this.SumB), 6);
		},
		DdivC(){
			return this.round(parseFloat(this.SumD) / parseFloat(this.SumC), 6);
		},
	},
	methods: {
		round(val, dec){
			val = parseFloat(val);
			return isNaN(val) ? '' : val.toFixed(dec);
		},
		dataSum(key, dec){
			if (!this.data || !Array.isArray(this.data.summer_list)) return '';
			return this.data.summer_list.reduce((sum, item) => sum + parseFloat(item[key]), 0.0).toFixed(dec);
		},
		async onOpen(){
			this.valueInput = '';
			this.data = {};
			this.loading = true;
			this.data = await this.imodel.getHwM3ToMwhData(this.session, this.station);
			this.valueInput = this.valueInit && this.valueInit.toString() || '';
			this.loading = false;
		},
		close(){
			this.$refs.dialog.doClose();
		},
		async update(){
			if (!this.$refs.form.validate()) throw "Невалидна стойност"; // message not used
			return await this.imodel.updateHwM3ToMwh(this.session, this.station, this.valueInput);
		},
	},
}
</script>