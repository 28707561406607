<template>
	<v-dialog v-bind="$attrs" v-on="$listeners" v-model="open" width="500">
		<template v-slot:activator="scope">
			<slot name="activator" v-bind="scope"/>
		</template>
		<v-card>
			<edit-column
				title="Ръчни данни"
				:params="{session_id:session.id,station_id:stationId}"
				v-if="open"
				:fields="fields"
				@close="open = false"
				@saveclose="open = false; $emit('reload')"
				:edit-button-options="{showDelete:false,showSave:!readonly}"
				:left-col-width="250"
			>

				<template v-slot:field.sum_radiator_power_manual="{row,err}">
					<div class="d-flex align-center">
						<edit-input name="sum_radiator_power_manual" outlined dense label="" :data="row" :errors="err" />
						<btn-loading-promise :click="()=>calc_sum_radiator_power(row)">
							<template #default="{click,loading}">
								<v-btn small fab icon @click="click" :loading="loading"><v-icon>mdi-refresh</v-icon></v-btn>
							</template>
						</btn-loading-promise>
					</div>
				</template>

				<template v-slot:field.e_sgri_dobavena_manual="{row,err}">
					<edit-input name="e_sgri_dobavena_manual" outlined dense label="" :data="row" :errors="err" />
				</template>

			</edit-column>
		</v-card>
	</v-dialog>
</template>

<script>
import {StationDataCalc} from "../stations/ModelData";
import EditColumn from "@/ittijs/EditColumn";
import EditInput from "@/ittijs/Inputs/EditInput";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise";
// import MsrsCalc from "@/views/clients_screen/MsrsCalc";

const model = new StationDataCalc();

export default {
	components: {
		// MsrsCalc,
		BtnLoadingPromise,
		EditInput,
		EditColumn,
	},
	props: [
		'session',
		'stationId',
		'readonly',
	],
	data(){
		return {
			open: false,
			fields:[
				'sum_radiator_power_manual',
				'e_sgri_dobavena_manual',
			],
		}
	},
	provide: {
		imodel: model,
	},
	methods: {
		async calc_sum_radiator_power(row){
			row.sum_radiator_power_manual = await model.calc_sum_radiator_power(
				this.stationId,
				this.session.id
			);
		},
	},
}
</script>
