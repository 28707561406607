<template>
	<data-loader :callback="loadData" :data.sync="data">
		<template #default>
			<my-dialog v-if="service" v-model="dialog" max-width="1200" title="Обработка на монтажна дейност">

				<template #activator="{attrs,on}">
					<slot name="activator" v-bind="{attrs,on}">
						<v-btn v-bind="attrs" v-on="on" fab depressed small color="warning" class="ma-2">
							<v-icon>mdi-tools</v-icon>
							<v-badge v-if="remaining" :content="remaining + 1" />
						</v-btn>
					</slot>
				</template>

				<v-container v-if="service.notes_operator!='' || service.notes_montajnik!=''">
					<v-row dense>
						<v-col cols="6">
							<v-textarea readonly outlined dense hide-details :value="service.notes_operator" label="Бележки оператор" />
						</v-col>
						<v-col cols="6">
							<v-textarea readonly outlined dense hide-details :value="service.notes_montajnik" label="Бележки монтажник" />
						</v-col>
					</v-row>
				</v-container>

				<dev-form-replace
					v-if="dialog && service.work_type === 'replace'"
					ref="form"
					:model="modelReplace"
					hide-title
					edit-buttons=""
					third-col
					:right-col-width="400"
					:third-col-attrs="{
						style: 'flex: 0 1 400px',
						class: 'pa-1'
					}"
					:session="session"
					:device="data.device.id"
					@save="onSaved"
				>
					<!-- KEEP THIS TEMPLATE THE SAME AS THE ONE BELOW -->
					<template v-for="(n_srv, n_dev) in srvFieldMap" v-slot:[`third.${n_dev}`]="{row,imodel}">
						<div class="d-flex" :key="n_dev">
							<v-btn icon @click="$set(row, n_dev, service[n_srv])" :color="row[n_dev] == service[n_srv] || service[n_srv] == null ? 'grey lighten-1' : 'primary'">
								<v-icon>mdi-arrow-left-bold-box</v-icon>
							</v-btn>
							<v-switch-case :value="n_dev">
								<template #default>
									<model-input :imodel="imodel" :name="n_dev" :value="service[n_srv]" outlined dense :readonly="true" :disabled="true" label="" />
								</template>
								<template #device_model_id>
									<device-model-select-base
										:value="service[n_srv]"
										outlined dense :readonly="true" :disabled="true" label=""
									/>
								</template>
							</v-switch-case>
						</div>
					</template>
					<template #after-fields="{row}">
						<div class="text-right py-2">
							<v-btn @click="copyAll(row)" small>
								<v-icon left>mdi-arrow-left-bold-box</v-icon>
								Прехвърли всичко
							</v-btn>
						</div>
					</template>
				</dev-form-replace>

				<dev-form-edit
					v-else-if="dialog"
					ref="form"
					:model="modelEdit"
					hide-title
					edit-buttons=""
					third-col
					:right-col-width="400"
					:third-col-attrs="{
						style: 'flex: 0 1 400px',
						class: 'pa-1'
					}"
					:session="session"
					:device="data.device.id"
					@save="onSaved"
				>
					<!-- KEEP THIS TEMPLATE THE SAME AS THE ONE ABOVE -->
					<template v-for="(n_srv, n_dev) in srvFieldMap" v-slot:[`third.${n_dev}`]="{row,imodel}">
						<div class="d-flex" :key="n_dev">
							<v-btn icon @click="$set(row, n_dev, service[n_srv])" :color="row[n_dev] == service[n_srv] || service[n_srv] == null ? 'grey lighten-1' : 'primary'">
								<v-icon>mdi-arrow-left-bold-box</v-icon>
							</v-btn>
							<v-switch-case :value="n_dev">
								<template #default>
									<model-input :imodel="imodel" :name="n_dev" :value="service[n_srv]" outlined dense :readonly="true" :disabled="true" label="" />
								</template>
								<template #device_model_id>
									<device-model-select-base
										:value="service[n_srv]"
										outlined dense :readonly="true" :disabled="true" label=""
									/>
								</template>
							</v-switch-case>
						</div>
					</template>
					<template #after-fields="{row}">
						<div class="text-right py-2">
							<v-btn @click="copyAll(row)" small>
								<v-icon left>mdi-arrow-left-bold-box</v-icon>
								Прехвърли всичко
							</v-btn>
						</div>
					</template>
				</dev-form-edit>

				<template #actions>
					<span v-if="remaining" class="warning white--text rounded px-1">
						има още {{ remaining }} извършени дейности
					</span>
					<v-spacer/>
					<edit-buttons @close="doClose" @save="doSave"></edit-buttons>
				</template>

			</my-dialog>
		</template>
	</data-loader>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import EditButtons from "@/ittijs/EditButtons.vue";
import DevFormEdit from "@/ittijs/components/DeviceDataList/DevFormEdit.vue";
import DevFormReplace from "@/ittijs/components/DeviceDataList/DevFormReplace.vue";
import ModelInput from "@/ittijs/Inputs/ModelInput.vue";
import VSwitchCase from "@/ittijs/components/vSwitchCase.vue";
import DeviceModelSelectBase from "@/views/device_models/DeviceModelSelectBase.vue";
import ModelDev from "@/views/devices/Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

class ModelBase extends ModelDev {
	constructor(className) {
		super(className);
		this.jobServiceID = null;
	}
	save(params, data) {
		params.job_service_id = this.jobServiceID;
		return super.save(params, data);
	}
}

class ModelEdit extends ModelBase {
	constructor() {
		super('SyncDeviceMontajEdit');
	}
}

class ModelReplace extends ModelBase {
	constructor() {
		super('SyncDeviceMontajReplace');
	}
}

const model = new ITTIModelBase('SyncDeviceMontaj');

export default {
	components: {
		DeviceModelSelectBase,
		VSwitchCase,
		ModelInput,
		DevFormReplace,
		DevFormEdit,
		EditButtons,
		DataLoader,
		MyDialog,
	},
	props: {
		device: {},
		session: {},
	},
	data(){
		return {
			data         : null,
			dialog       : false,
			modelEdit    : ITTIModelProvider.getModel(ModelEdit),
			modelReplace : ITTIModelProvider.getModel(ModelReplace),
		}
	},
	computed: {
		service(){
			// От сървъра получаваме списък с монтажните дейности, но на този екран ги обработваме едно по едно.
			// Тук връщаме първата дейност - след като тя е обработена, минаваме към следващата.
			return this.data?.services[0] ?? null;
		},
		remaining(){ // колко още дейности има в опашката
			return this.data?.services.length - 1;
		},
		srvFieldMapEdit(){
			return {
				'device_type_code'     : 'new__device_type_code',
				'device_model_id'      : 'new__device_model_id',
				'device_N'             : 'new__device_N',
				'radio_N'              : 'new__radio_N',
				'plomba_N'             : 'new__plomba_N',
				'mount_date'           : 'new__mount_date',
				'mount_value'          : 'new__mount_value',
				'reinstall_date'       : 'new__reinstall_date',
				'wpl_id'               : 'new__wpl_id',
				'room'                 : 'new__room',
				'radiator_description' : 'new__radiator_description',
				'notes'                : 'new__notes',
			}
		},
		srvFieldMapReplace(){
			return {
				'old__device_N'        : 'old__device_N',
				'old__unmount_date'    : 'old__unmount_date',
				'old__unmount_value'   : 'old__unmount_value',
				'old__unmount_value2'  : 'old__unmount_value2',
				'device_type_code'     : 'new__device_type_code',
				'device_model_id'      : 'new__device_model_id',
				'device_N'             : 'new__device_N',
				'radio_N'              : 'new__radio_N',
				'plomba_N'             : 'new__plomba_N',
				'mount_date'           : 'new__mount_date',
				'mount_value'          : 'new__mount_value',
				'reinstall_date'       : 'new__reinstall_date',
				'wpl_id'               : 'new__wpl_id',
				'room'                 : 'new__room',
				'radiator_description' : 'new__radiator_description',
				'notes'                : 'new__notes',
			}
		},
		srvFieldMap(){
			switch (this.service.work_type) {
				case 'edit':
					return this.srvFieldMapEdit;
				case 'replace':
					return this.srvFieldMapReplace;
			}
			return {};
		},
	},
	watch: {
	},
	methods: {
		loadData() {
			return model.fetch('getData', {
				device: this.device,
				session: this.session,
			});
		},
		copyAll(row) {
			for (const nDev in this.srvFieldMap) {
				const nSrv = this.srvFieldMap[nDev];
				this.$set(row, nDev, this.service[nSrv]);
			}
		},
		doClose(){
			this.dialog = false;
		},
		doSave(){
			this.modelEdit.jobServiceID    = this.service?.id ?? null;
			this.modelReplace.jobServiceID = this.service?.id ?? null;
			return this.$refs.form?.doSave();
		},
		onSaved(){
			this.doClose();
			this.$emit('reload');
		},
	}
}
</script>