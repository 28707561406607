<template>
	<v-row dense>
		<v-col cols="3">

			<table-data-view-model class="mb-2" :fields="[
					// Administrativni danni a kontakt na AS
					'station_N',
					smodel.fields.building_N,
					'mesechen',
					'domoupravitel_name',
					'domoupravitel_phone',
					'domoupravitel_email',
					'authorized_persons',
				]" :title="data.address" :data="{
					...data,
					building_N: station.building_N,
				}">
				<template #after-rows v-if="station.notes">
					<tr>
						<td colspan="2" class="py-2">
							<strong>Забележка:</strong>
							<div style="white-space: pre-wrap">{{ station.notes }}</div>
						</td>
					</tr>
				</template>
			</table-data-view-model>

			<table-data-view-model class="mb-2" :fields="[
					// Parvichni danni parametri na AS
					'VID_SGR',
					smodel.fields.install_power_heat,
					'sgri_2_zones',
					'sgri_method',
					'sgri_percent',
					'sgri_percent_2',
					'sgri_moshtnost',
					'water_type',
					'status',
					//'otchetnik_id',
				]" title="Първични данни параметри на АС" :data="{
					...data,
					install_power_heat: station.install_power_heat,
				}" />

<!--			<table-data-view-->
<!--				class="mb-2"-->
<!--				:fields="Object.keys(data.calcData)"-->
<!--				title="Междинни калкулации"-->
<!--				:data="data.calcData"-->
<!--			/>-->

		</v-col>
		<v-col cols="3">

			<table-data-view-model class="mb-2" :fields="[
					// Parvichni danni ot Toplofikacia
					'work_days_hw',
					'work_days_heat',
					'work_days_wo_hot_vodomer',
					't_avg_session',
					'day_degree',
					'temperaturen_coeficient',
					'toplomer_obsht_value',
					'toplomer_heat_value',
					'vodomer_hot_value',
					'vodomer_hot_calculated',
					'vodomer_cold_value',
					'studomer_value',
					'max_hw_m3_to_mwh',
				]" title="Първични данни от дружеството" :data="data" />

			<table-data-view-model class="mb-2" :fields="[
					// Izchisleni danni na AS
					'sum_volume',
					'sum_radiator_power',
					'sum_reded',

					'k_bgv_kor_m3',
					'k_bgv_kor_brl',
					'hw_m3_to_mwh_summer',
					'base_hw_power',

					'msrs',
					'corekcionen_coeficient',
					'reded_to_mwh',
					'e_hot_water',
					'e_hot_water_over',
					'e_heat_and_sgri',
					'e_sgri',
					'e_sgri_dobavena',
					'e_uredi_razpr',
					'e_uredi_toplomer',
					'sgri_percent_izr',
					'voda_za_razpr_m3',

				]" title="Изчислени данни на АС" :data="data" />

		</v-col>
		<v-col cols="6">

			<v-card outlined class="mb-2">
				<v-card-title>Бележки</v-card-title>
				<v-card-text>
					<station-notes :station="data.station_id" />
				</v-card-text>
			</v-card>

			<v-card outlined class="mb-2">
				<v-card-title>Документи</v-card-title>
				<v-card-text>
					<document-list
						:station="data.station_id"
						:documents.sync="documents"
					/>
				</v-card-text>
			</v-card>

			<v-card outlined class="mb-2">
				<v-card-title>Деловодство</v-card-title>
				<v-card-text>
					<tickets
						:station="data.station_id"
						no-new-record
					/>
				</v-card-text>
			</v-card>

			<table-data-view-model v-if="data.mesechen!=='1'" class="mb-2" :fields="[
				'izr_hw_m3_to_mwh',
				'izr_k_bgv_kor_m3',
				'izr_k_bgv_kor_brl',
				'izr_rd_bgv',
			]" title="Данни от изравнителна" :data="data" />

			<v-card outlined class="mb-2" v-if="data.qMax_devices">
				<v-card-title>qMax_devices</v-card-title>
				<v-card-text>
					<pre style="max-height: 400px; overflow: auto">{{data.qMax_devices}}</pre>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
// import AvgTempCalc from './AvgTempCalc.vue'
import StationModel from '../stations/Model'
import StationDataModel from '../stations/ModelData'
import StationNotes from "./StationNotes";
// import TableDataView from "@/ittijs/components/TableDataView";
import TableDataViewModel from "@/ittijs/components/TableDataViewModel";
import DocumentList from "@/views/clients_screen/DocumentList";
import Tickets from "@/views/frontdesk/Tickets.vue";

const smodel = new StationModel
const sdmodel = new StationDataModel

export default {
	components: {
		Tickets,
		DocumentList,
		TableDataViewModel,
		// TableDataView,
		StationNotes,
		// AvgTempCalc,
	},
	provide: {
		imodel: sdmodel,
	},
	props: [
		'station', // n_station
		'data', // n_station_data
		'session',
	],
	data(){
		return {
			editTempAvg: false,
			tAvgSession: "",
			updating: false,
			documents: [],
			smodel,
		}
	},
	methods: {
		popupOpen(){
			this.tAvgSession = this.data.t_avg_session;
		},
		popupCancel(){
			this.tAvgSession = this.data.t_avg_session;
			this.editTempAvg = false;
		},
		async popupUpdate(){
			if (this.updating) return;
			this.updating = true;
			await sdmodel.save(this.data.id, {t_avg_session:this.tAvgSession});
			this.updating = false;
			this.editTempAvg = false;
			this.$emit('reload');
		},
	},
}
</script>